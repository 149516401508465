import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Icon } from "../../components/Icon";

import ProfileGamesPage from './ProfileGamesPage/ProfileGamesPage';
import ProfileRoomsPage from './ProfileRoomsPage/ProfileRoomsPage';
import ProfileWalletPage from "./ProfileWalletPage.jsx/ProfileWalletPage";

const ProfilePage = () => {
    const { t } = useTranslation();
    const user = useSelector((state) => state.currentUser.user);
    const navigate = useNavigate();
    let reputation =10;

    const type = useParams().type;

    const tabClick=(e)=>{
        navigate('/profile/'+e.target.id);
    }

    return (<>
        <div className="profileMainBlock prFlexRow">
            <div className="photoUploadCover"><Icon type='photoUpload'></Icon>
            </div>
            <div className="profileMainBlockInfo prFlexRow">  
                <div className="headerUserAvatarBlock">
                    <div className='headerUserAvatar'>
                        <Icon type='avatar'></Icon>
                    </div>
                    <div className="headerUserAvatarUpload">
                        <Icon type='photoUpload'/>
                    </div>
                </div>
                <div className="profileMainBlockInfoRight">
                    <div className="prFlexRow profileMainBlockInfoRightText">
                        <div className='prFlexRow'>
                            <div className='reputationNumber'>{reputation}</div>
                        </div>
                        <div className="profileFio">{t('last_name')} {t('first_name')}</div>
                        <div style={{paddingTop:'3px'}} className='profileFioEditName'><Icon type='editName'></Icon></div>
                    </div>
                    <div className="profileMainBlockInfoRightNick">
                        @{user?.nickname}
                    </div>
                    <div className="profileStatus">
                        <div className='prFlexRow'>
                            <div className='reputationNumber prFlexRow'> <Icon type='passport'></Icon> <div>{t('status_verificated')}</div> </div>
                        </div>
                    </div>
                    <div className="prFlexRow statistic">
                        <div className="prFlexRow white"><Icon type='playController'/><div className="statisticValue">&nbsp;: 42</div></div>
                        <div className="prFlexRow green"><Icon type='win'/><div className="statisticValue">&nbsp;: 30</div></div>
                        <div className="prFlexRow red"><Icon type='loose'/><div className="statisticValue">&nbsp;: 10</div></div>
                        <div className="prFlexRow orange"><Icon type='draft'/><div className="statisticValue">&nbsp;: 2</div></div>
                    </div>
                </div>

            </div>
            <div className="profileTabs prFlexRow">
                <div onClick={tabClick} id='myGames' className={!type || type=='myGames'? "profileTabsItems active ":"profileTabsItems"}>{t('my_games')}</div>
                <div onClick={tabClick} id='rooms' className={type=='rooms' ?"profileTabsItems active":"profileTabsItems"}>{t('rooms')}</div>
                <div onClick={tabClick} id='wallet' className={type=='wallet' ?"profileTabsItems active":"profileTabsItems"}>{t('wallet')}</div>
                <div onClick={tabClick} id='settings' className={type=='settings' ?"profileTabsItems active":"profileTabsItems"}>{t('settings')}</div>
            </div>
        </div>

        {(!type || type=='myGames' )&& 
            <ProfileGamesPage></ProfileGamesPage>
        }
        {
            type && type==='rooms' &&
            <ProfileRoomsPage></ProfileRoomsPage>
        }
        {
            type && type==='wallet' &&
            <ProfileWalletPage/>
        }

        </>
    );
};

export default ProfilePage;