import React from 'react';
import {Route,Routes} from 'react-router-dom';
import Page404 from './pages/404Page/Page404';
import AuthPage from './pages/Authorization/AuthPage/AuthPage';
import RestorePage from './pages/Authorization/RestorePage/RestorePage';
import RegistrationPage from './pages/Authorization/RegistrationPage/RegistrationPage';
import RoomPage from './pages/RoomPage/RoomPage';
import StartPage from './pages/StartPage/StartPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import UsersPage from './pages/UsersPage/UsersPage';
import UserEditPage from './pages/UserEditPage/UserEditPage';
import DirectoryPage from './pages/DirectoryPage/DirectoryPage';


const UseRoutes = (props) => {

    return(
        <Routes>
            {!props.isLoggedIn && <Route path='/login'  element={<AuthPage/>}/>}
            {!props.isLoggedIn &&<Route path='/registration'  element={<RegistrationPage/>}/>}
            {!props.isLoggedIn &&<Route path='/restore'  element={<RestorePage/>}/>}
            {<Route path="/room/:id" element={<RoomPage/>}/>}
            {!props.isLoggedIn && <Route path="/" element={<AuthPage/>}/>}
            {props.isLoggedIn && <Route path="/" element={<ProfilePage/>}/>}
            {props.isLoggedIn && <Route path="/profile/:type" element={<ProfilePage/>}/>}
            {/* {auth.isLoggedIn && <Route path="/" element={<StartPage/>}/>} */}
            {props.isLoggedIn && <Route path="/users" element={<UsersPage/>}/>}
            {props.isLoggedIn && <Route path="/userEdit/:id" element={<UserEditPage/>}/>}
            {props.isLoggedIn && <Route path="/directory" element={<DirectoryPage/>}/>}
            
            <Route path='/page404' element={<Page404/>}/>
            <Route path='*' element={<Page404/>}/>


            
        </Routes>
    
    )
}


export default UseRoutes;